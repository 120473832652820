<template>
  <div class="admin-tools-quries">
    <div class="table-responsive" :class="{skeleton: !state.loaded}" v-if="state.extracts.length">
      <table class="table table-hover">
        <thead>
        <tr>
          <th class="text-center">
            <span>번호</span>
          </th>
          <th>
            <span>제목</span>
          </th>
          <th>
            <span>설명</span>
          </th>
          <th>
            <span>용도</span>
          </th>
          <th>
            <span>이슈 URL</span>
          </th>
          <th>
            <span>엑셀 다운로드</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(e, idx) in state.extracts" :key="idx">
          <td class="text-center">
            <span>{{ e.idx + 1 }}</span>
          </td>
          <td>
            <span>{{ e.title }}</span>
          </td>
          <td>
            <span>{{ e.desc }}</span>
          </td>
          <td>
            <span>{{ e.usage }}</span>
          </td>
          <td>
            <a class="pointer-u" :href="e.issueUrl" target="_blank" rel="noopener noreferrer" v-if="e.issueUrl || !state.loaded">바로가기</a>
          </td>
          <td>
            <a class="pointer-u color-anchor" rel="noopener noreferrer" @click="download(e)">엑셀 다운로드</a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <NoData v-else-if="state.loaded"/>
    <form :id="`${component.name}Form`" class="hide" method="post">
      <input name="params" type="hidden"/>
    </form>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Wait from "@/components/Wait";
import http from "@/scripts/http";
import NoData from "@/components/NoData";
import PaginationJpa from "@/components/PaginationJpa";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "pageAdminToolsExtracts";
  this.initialize = initialize;
}

export default defineComponent({
  components: {PaginationJpa, NoData, Wait},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      loaded: false,
      args: {
        page: 0,
        size: 10
      },
      extracts: [],
    });

    const download = (e) => {
      store.commit("openModal", {
        name: "ExtractForm",
        params: {
          url: `/download/admin/extracts/${e.idx}/excel`,
          title: e.title,
          desc: e.desc,
          notices: e.notices,
          items: e.params,
        }
      });
    };

    const load = (page) => {
      state.total = 0;
      state.args.page = page || 0;
      state.loaded = false;

      for (let i = 0; i < 5; i += 1) {
        state.extracts.push({
          idx: 0,
          title: "Please wait a moment",
          desc: "Please wait a moment",
          usage: "Wait",
        });
      }

      http.get("/api/admin/extracts", state.args).then(({data}) => {
        state.loaded = true;
        state.extracts = data.body;
      });
    };

    return {component, state, load, download};
  }
});
</script>

<style lang="scss" scoped>
.admin-tools-quries {
  padding-bottom: $px25;

  .table-responsive {
    table {
      tr {
        th, td {
          padding-left: $px20;
          padding-right: $px20;
        }

        th {
          background: $colorBackground;
          border-bottom: 0;
        }
      }
    }

    &.skeleton {
      table tr {
        th, td {
          span, a {
            @include skeleton;
          }
        }
      }
    }
  }

  .no-data {
    padding: $px50 0;
  }
}
</style>